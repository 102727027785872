import React from "react";
import { FiHeart } from "react-icons/fi";

import "./style.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <p>
          <a
            href="https://www.distillerie-gessienne.fr/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions Légales
          </a>
        </p>
        <p>
          Réalisé avec{" "}
          <span>
            <FiHeart />
          </span>{" "}
          par{" "}
          <a
            href="https://www.zelda.fr"
            class="footer__container--link"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              data-name="Calque 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 59.68 12.12"
              class="footer__container--logo"
            >
              <defs></defs>
              <path
                class="cls-1"
                d="M4.53,10.54A4.3,4.3,0,0,1,2.45,10L7.2,5V3.53H.07V5.09H4.85L0,10.23V12H.53l.81-.87a5.2,5.2,0,0,0,3.07.95,5.07,5.07,0,0,0,3.12-1V9.34a4.13,4.13,0,0,1-3,1.2"
              />
              <path
                class="cls-1"
                d="M12.87,4.85A2.75,2.75,0,0,1,15.65,7H10.11a2.77,2.77,0,0,1,2.76-2.1m4.49,4.4H15.52l0,.06a2.94,2.94,0,0,1-2.55,1.3,2.76,2.76,0,0,1-2.85-2.18h7.32a4.42,4.42,0,0,0-4.54-5.09,4.4,4.4,0,1,0,.05,8.78,4.53,4.53,0,0,0,4.44-2.87"
              />
              <path
                class="cls-1"
                d="M43.54,10.54A2.82,2.82,0,1,1,46.4,7.73a2.79,2.79,0,0,1-2.86,2.81m4.53-.88V.17H46.4V4.81a3.93,3.93,0,0,0-3.13-1.47A4.25,4.25,0,0,0,39,7.73a4.25,4.25,0,0,0,4.31,4.39,3.94,3.94,0,0,0,3.2-1.57,1.84,1.84,0,0,0,2,1.37h.57V10.35h-.29a.65.65,0,0,1-.71-.69"
              />
              <path
                class="cls-1"
                d="M54.15,10.54A2.82,2.82,0,1,1,57,7.73a2.79,2.79,0,0,1-2.86,2.81m5.24-.19a.65.65,0,0,1-.71-.69V3.53H57V4.81a3.93,3.93,0,0,0-3.13-1.47,4.25,4.25,0,0,0-4.31,4.39,4.25,4.25,0,0,0,4.31,4.38,3.93,3.93,0,0,0,3.2-1.56,1.84,1.84,0,0,0,2,1.37h.57V10.35Z"
              />
              <path
                class="cls-1"
                d="M20.58,4.36c0-3,1.28-2.78,1.28-2.78.87,0,1.37,1.22,1.12,3.27a7.49,7.49,0,0,1-1.44,3.5,8,8,0,0,1-1-4m4.17-.45c0-2.55-1.2-3.91-2.88-3.91s-3,1.32-3,4.47a8.64,8.64,0,0,0,1.41,5.08,3.54,3.54,0,0,1-1.11.64,2.7,2.7,0,0,1-1.05.17A5.53,5.53,0,0,1,17,11.8a5.61,5.61,0,0,0,2.33,0,5.51,5.51,0,0,0,2.12-1.06,4.82,4.82,0,0,0,2,1l1.27-1.43a3.31,3.31,0,0,1-2-.73,8.94,8.94,0,0,0,2.13-5.72"
              />
              <path
                class="cls-1"
                d="M27,4.36c0-3,1.28-2.78,1.28-2.78.87,0,1.36,1.22,1.12,3.27A7.49,7.49,0,0,1,28,8.35a8,8,0,0,1-1-4m4.11,6a2.84,2.84,0,0,1-1.05-.18,4,4,0,0,1-1-.55,8.88,8.88,0,0,0,2.13-5.72C31.19,1.36,30,0,28.31,0s-3,1.32-3,4.47a8.64,8.64,0,0,0,1.41,5.08,3.52,3.52,0,0,1-1.12.64,2.32,2.32,0,0,1-.95.17l-1.27,1.43a5.5,5.5,0,0,0,2.33.05,5.5,5.5,0,0,0,2.13-1.06A5.51,5.51,0,0,0,30,11.84a6.3,6.3,0,0,0,2.43-.05Z"
              />
              <path
                class="cls-1"
                d="M33.45,4.36c0-3,1.29-2.78,1.29-2.78.87,0,1.36,1.22,1.12,3.27a7.49,7.49,0,0,1-1.44,3.5,7.9,7.9,0,0,1-1-4m4.91,5.89a3,3,0,0,1-1.66,0,4,4,0,0,1-1.19-.61,8.94,8.94,0,0,0,2.13-5.72c0-2.55-1.2-3.91-2.88-3.91s-3,1.32-3,4.47a8.73,8.73,0,0,0,1.39,5.09,3.63,3.63,0,0,1-2,.8l1.27,1.43a5.26,5.26,0,0,0,2-1,5.51,5.51,0,0,0,2.12,1.06,5.65,5.65,0,0,0,2.32,0h0a4,4,0,0,0,.46-.15l0,0a5.33,5.33,0,0,1-1-1.38"
              />
            </svg>
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
